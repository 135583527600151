import "../css/gutenberg.css"
import "twin.macro"
import { graphql } from "gatsby"
import AnchorNavigation from "../components/anchor-navigation"
import Blocks from "../components/blocks"
import Container from "../components/container"
import extendBlocksWithSlugs from "../helpers/extend-blocks-with-slugs"
import React from "react"
//import { Head as HeadSeo } from "../components/head"

const PageTemplate = ({ data: { page, seo } }) => {
  const blocks = page.blocks?.blocks || []
  const [filteredBlocks, anchors, hasAnchors] = extendBlocksWithSlugs(blocks)
  return (
    <>
      <article
        className="blog-page"
        itemScope
        itemType="http://schema.org/Article"
      >
        {/* if we have a featured image for this page let's display it */}
        {/* {featuredImage?.data && (
          <GatsbyImage
            image={featuredImage.data}
            alt={featuredImage.alt}
            style={{ marginBottom: 50 }}
          />
        )} */}

        <Container>
          <h1 tw="mb-4 max-w-3xl text-3xl font-bold lg:mb-9 lg:text-5xl">
            {page.title}
          </h1>
          <div tw="grid grid-cols-12 gap-y-10 lg:gap-5">
            {hasAnchors && (
              <div tw="relative col-span-12 lg:col-span-3 xl:col-span-2">
                <AnchorNavigation anchors={anchors} />
              </div>
            )}
            <div tw="col-span-12 col-start-1 lg:col-span-9 lg:col-start-4 xl:col-start-3">
              <Blocks blocks={filteredBlocks} />
            </div>
          </div>
        </Container>
      </article>
    </>
  )
}

export default PageTemplate
/**export const Head = props => {
  const propsWithTitle = {
    ...props,
    data: {
      ...props.data,
      seo: {
        seo: {
          ...props.data.seo.seo,
          // title: props.data.event.title,
        },
      },
    },
  }

  return <HeadSeo {...propsWithTitle} />
}**/

export const pageQuery = graphql`
  query PageById($id: String!) {
    #G#breadcrumbs: wpPage(id: { eq: $id }) {
    #G#  ...BreadcrumbsFragment
    #G#}
    #G#seo: wpPage(id: { eq: $id }) {
    #G#  ...PageSeoFragment
    #G#}
    page: wpPage(id: { eq: $id }) {
      nodeType
      id
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      ...BlocksFragment
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  }
`
